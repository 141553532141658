import React, {Component} from "react";
import socket from "../../../../Socket";
import {decode, formatAmount} from "../../../../Helper";
import moment from "moment";
import {Box, Grid} from "@mui/material";
import Countdown from "react-countdown";

class PowerBreakfast extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
    }

    componentDidMount() {
        if (this._isMounted) {
            socket.emit('TOP_PLAYS');
            socket.on('TOP_PLAYS', data => this.setTopPlays(decode(data)));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setTopPlays(data) {
        this.setState({items: data});
    }

    render() {
        let current_hour = parseInt(moment().format("HH"));
        let current_date = moment().format("YYYY-MM-DDT");

        const list = this.state.items.map((item, i) =>
            <Items key={i} index={i + 1} item={item}/>
        );

        return (
            <Box sx={{flexGrow: 1}}>
                {(current_hour >= 6 & current_hour <= 8) ?
                    <Grid container spacing={0}>
                        <Grid style={{display: 'inline-flex', color: '#000000'}} xs={10}>
                            Top Players: {list.length === 0 ? <div>&nbsp;|&nbsp;Loading...</div> : list}
                        </Grid>
                        <Countdown zeroPadTime={2} date={current_date + "08:59:59"} renderer={renderer}/>
                    </Grid> : <div className={'text-center'} style={{color: '#000000', fontWeight: 'bold'}}>Promotions runs again tomorrow from 6am to 8am!</div>}
            </Box>
        );
    }
}

class Items extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {item} = this.props;

        let username = item.name;
        let plays = item.plays;

        return (
            <>
                &nbsp;|&nbsp;{username}: <b style={{color: '#000000', fontWeight: 'bold'}}>{formatAmount(plays, 0)}</b>
            </>
        );
    }
}

const renderer = ({formatted}) => {
    return (
        <Grid style={{display: 'inline-flex'}} xs={2}>
            <div className={'top-count-down'}>{formatted.hours}</div>
            :
            <div className={'top-count-down'}>{formatted.minutes}</div>
            :
            <div className={'top-count-down'}>{formatted.seconds}</div>
        </Grid>
    );
};

export default PowerBreakfast;