import Carousel from 'react-bootstrap/Carousel';
import {BANNER_PROMO_1, BANNER_PROMO_2, Event} from "../../../Helper";
import ReferAndWin from "./includes/ReferAndWin";
import PowerBreakfast from "./includes/PowerBreakfast";

function showBannerBreakfast() {
    Event.emit('show_banner_breakfast');
}

function showBannerReferral() {
    Event.emit('show_banner_referral');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showBannerBreakfast}>
                <img className={"d-block w-100"} style={{borderRadius: '6px 6px 0px 0px'}} src={BANNER_PROMO_1} alt="Power Breakfast"/>
                <div className={'d-block w-100 custom-panel-lighter'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><PowerBreakfast/></div>
            </Carousel.Item>
            <Carousel.Item onClick={showBannerReferral}>
                <img style={{borderRadius: '6px 6px 0px 0px'}} className="d-block w-100" src={BANNER_PROMO_2} alt="Refer and Win"/>
                <div className={'d-block w-100 custom-panel-lighter'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><ReferAndWin/></div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;